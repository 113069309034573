import React, {Component} from 'react';
import './App.css';
import Sidebar from './components/sidebar';
import Home from './components/home';
import About from './components/about';
import Food from "./components/food";
import Team from "./components/team";
import Structural from "./components/structural";
import Contact from "./components/contact";

class App extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
          <Sidebar/>
          <div id="colorlib-main">
            <Home/>
            <About/>
            <Food/>
            <Team/>
            <Structural/>
            <Contact/>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
