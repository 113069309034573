import React, {Component} from 'react'

export default class Contact extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-contact" data-section="contact">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="contact-desc">
                      <span className="heading-meta">Kontakt</span>
                      <h2 className="colorlib-heading">So kann man uns erreichen</h2>
                      <p>Privatkindergarten<br/> Individuelle Kinderbetreuung</p>
                      <p>
                        <span className="address"><i className="icon-contacts"/> Grabenstrasse 20, 8010 Graz<br/></span>
                        <span className="email"><i className="icon-mail"/>office@individuelle-kinderbetreuung.at<br/></span>
                        <span className="phone"><i
                          className="icon-phone"/> 0670 / 60 444 90 <br/></span>
                      </p>
                      <p><br></br><br></br><br></br>===<br></br>
                        Für den Inhalt verantwortlich:<br></br>
                        <i>Verein für Individuelle Kinderbetreuung<br></br>
                          Grabenstraße 20, 8010 Graz<br></br>
                          Mail: office@individuelle-kinderbetreuung.at<br></br>
                          Tel.: 0670/60 444 90
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
