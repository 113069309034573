import React, {Component} from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">Über uns</span>
                      <h2 className="colorlib-heading">Individuelle Kinderbetreuung</h2>
                      <p>Herzlich willkommen in unserem kleinen,
                        gemütlichen und familiären Haus.</p>
                      <p>Wer zu uns kommt sollte keine Eile haben, denn bei uns richtet sich die
                        Uhr nach dem Tempo des Einzelnen. Unsere pädagogische Arbeit orientiert
                        sich an den Grundgedanken von Maria Montessori. Eine ansprechende und
                        liebevoll gestaltete vorbereitete Umgebung ladet die Kinder zum
                        freien Spielen ein. Dabei bleibt auch Zeit zum Plaudern, Trödeln und
                        Träumen.</p>
                      <p>Im Zweijahres-Rhythmus schicken wir unser Spielzeug auf Urlaub. Aus
                        den vielen neuen Ideen, die dabei entstehen, entwickeln sich spannende,
                        gemeinsame Projekte. Ein suchtpräventives Projekt im Kindergarten in
                        Kooperation mit Vivid. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="about-desc">
                      <h2 className="colorlib-heading">Unsere Werte</h2>
                      <p>Jeder darf sein wie er ist, das macht unsere Kindergartengemeinschaft
                        bunt und einzigartig.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row row-pt-md">
              <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                  <span className="icon"><i className="icon-user"/></span>
                  <div className="desc">
                    <h3>Miteinander</h3>
                    <p>Ein respektvolles, empathisches und wertschätzendes Miteinander.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                  <span className="icon"><i className="icon-heart"/></span>
                  <div className="desc">
                    <h3>Konflikte lösen</h3>
                    <p>Anbieten von lösungsorientierten, gewaltfreien Techniken und Strategien zur
                      Bewältigung von Konflikten, die im täglichen Kindergartenalltag geübt werden
                      können.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                  <span className="icon"><i className="icon-basecamp"/></span>
                  <div className="desc">
                    <h3>Chancen statt Probleme</h3>
                    <p>Gemeinsames Finden von Lösungen, um Probleme und Herausforderungen in
                      Möglichkeiten und Chancen verwandeln zu können.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="about-desc">
                      <h2 className="colorlib-heading">Auch die ELtern spielen bei uns eine große
                        Rolle</h2>
                      <p>Unser Kindergarten wird durch einen Elternverein geführt. Daher sehen wir die Eltern als unverzichtbare Partner, auf die wir zählen können.</p>
                      <ul>
                        <li>Die Mitarbeit im Elternverein und in den Arbeitskreisen ist ein essenzieller Baustein in unserem Kindergarten. </li>
                        <li>Der Besuch von Elternabenden ist die Grundlage für eine gute
                          Zusammenarbeit.
                        </li>
                        <li>Elterngespräche ermöglichen einen guten Austausch über kindliche
                          Entwicklungsschritte.
                        </li>
                        <li>Jede Familie ist an einer bestimmten Anzahl an Tagen im Jahr für das gesunde Jausenbuffet verantwortlich.
                        </li>
                        <li>Engagement bei der Gestaltung unserer Feste ist immer sehr
                          willkommen.
                        </li>
                        <li>Die Handwerker unter den Eltern unterstützen uns bei der Erledigung
                          von Reparaturen und Montagen in Haus und Garten.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
