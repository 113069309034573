import React, {Component} from 'react'

export default class Home extends Component {
  render() {
    return (
      <div>
        <section id="colorlib-hero" className="js-fullheight" data-section="home">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li
                style={{backgroundImage: 'url(https://res.cloudinary.com/kecke-schnecke/image/upload/c_scale,f_auto,q_auto,w_2000/v1608365267/kindergarten-website/herbst-garten.jpg)'}}>
                <div className="overlay"/>
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <h1>Wir sind gerne draußen</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                style={{backgroundImage: 'url(https://res.cloudinary.com/kecke-schnecke/image/upload/c_scale,f_auto,q_auto,w_2000/v1608365252/kindergarten-website/kinder-in-karton.jpg)'}}>
                <div className="overlay"/>
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1>Bei uns wird viel gespielt...</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                style={{backgroundImage: 'url(https://res.cloudinary.com/kecke-schnecke/image/upload/c_scale,f_auto,q_auto,w_2000/v1608365312/kindergarten-website/buben-spielen.jpg)'}}>
                <div className="overlay"/>
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1>...gewerkelt und gemalt...</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                style={{backgroundImage: 'url(https://res.cloudinary.com/kecke-schnecke/image/upload/c_scale,f_auto,q_auto,w_2000/v1608616610/kindergarten-website/hirselaibchen-auf-teller.jpg)'}}>
                <div className="overlay"/>
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1>...und gut gegessen!</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    )
  }
}
